<template>
  <div>
    <div v-if="this.$route.name === 'Receipt'">
      <Receipt />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import Receipt from "@/views/companyApp/receipt/Receipt.vue";

export default {
  name: 'ReceiptParent',
  components: {
    Receipt
  }
}
</script>
